import type { MultipleQueriesResponse } from '@algolia/client-search'
import dayjs from 'dayjs'
import { HITS_PER_SHOWCASE } from './algolia/constants'
import type { AlgoliaEventItem } from '~/composables/types/algolia'
export { useAlgoliaIndexNames } from './algolia/useAlgoliaIndexNames'

export const useBoxEvents = async (
  filters,
  itemsPerPage = HITS_PER_SHOWCASE
) => {
  const searchClient = useAlgoliaRef()
  const { locale } = useI18n()

  const boxQueryParams = [
    {
      indexName: getBoxesIndexName(locale.value),
      facetFilters: [`categories.name:${filters?.category}`],
      hitsPerPage: itemsPerPage,
    },
  ]

  const {
    data: algoliaResults,
    pending,
    status,
  } = await useLazyAsyncData<MultipleQueriesResponse<AlgoliaEventItem>>(
    `algoliaResults-box-${process.client}`,
    () => {
      return searchClient.search(boxQueryParams)
    }
  )

  return {
    algoliaResults,
    pending,
    status,
  }
}
