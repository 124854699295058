<template>
  <div
    class="flex flex-col py-6 md:py-20 w-screen overflow-hidden"
    :class="[
      twMerge(content?.background === 'dark' && 'bg-teal-900 text-stone-50'),
    ]"
  >
    <div class="w-screen md:max-w-screen-xl m-auto px-4">
      <h2
        class="my-2"
        :class="[twMerge(content?.background === 'dark' && 'text-stone-50')]"
      >
        {{ content?.title }}
      </h2>
      <h5 class="my-2">{{ content?.subtitle }}</h5>
    </div>
    <div
      ref="swiperSlider"
      class="slider-wrapper w-screen md:max-w-screen-xl m-auto px-4"
    >
      <Swiper
        v-bind="sliderOptions"
        class="mySwiper !overflow-visible touch-pan-x pt-8"
      >
        <SwiperSlide
          v-for="item in sliderItems"
          :key="item?.link"
          class="py-3 !w-fit"
        >
          <NuxtLink
            :to="item?.link"
            class="p-3 m-4 rounded-xl border border-gray-200 flex h-[120px]"
          >
            <NuxtImg
              v-if="item?.image?.data?.attributes?.url"
              :src="String(item?.image?.data?.attributes?.url)"
              class="carousel-image w-[100px]"
            />
            <div v-if="item?.title" class="p-3 flex flex-col justify-center">
              <h5 class="mb">{{ item?.title }}</h5>
              <span>{{ item?.description }}</span>
            </div>
          </NuxtLink>
        </SwiperSlide>
      </Swiper>
    </div>
  </div>
</template>
<script setup lang="ts">
import { twMerge } from 'tailwind-merge'

import 'swiper/css'

import 'swiper/css/free-mode'
import 'swiper/css/pagination'
import { Autoplay } from 'swiper/modules'

const { content } = withDefaults(
  defineProps<{
    titleProps?: string
    content: any
  }>(),
  {
    titleProps: '',
    content: {},
  }
)

const repeatArray = (arr: any[], times: number) => {
  return Array(times).fill(arr).flat()
}

const swiperSlider = ref(null)

const sliderItems = computed(() => {
  if (!content?.items) return []

  return repeatArray(content?.items, content?.items.length < 10 ? 3 : 2)
})

const swiperSpeed = computed(() => {
  if (!swiperSlider.value) return 3000

  const scrollWidth = swiperSlider.value.scrollWidth
  const viewportWidth = window.innerWidth

  // different speed multiplier for mobile and desktop
  const speedMultiplier = viewportWidth < 768 ? 3 : 15
  const duration = (scrollWidth / viewportWidth) * speedMultiplier * 100

  return `${duration}`
})

const sliderOptions = computed(() => {
  return {
    modules: [Autoplay],
    loop: true,
    slidesPerView: 'auto',
    spaceBetween: 15,
    mousewheel: {
      forceToAxis: true,
    },
    centeredSlides: true,
    speed: swiperSpeed.value,
    ease: 'linear',
    autoplay: {
      delay: 0,
      disableOnInteraction: false,
      pauseOnMouseEnter: false,
    },
  }
})
</script>

<style lang="postcss">
.carousel-image {
  min-width: 5rem;
  height: 100%;
  object-fit: cover;
  @apply rounded-lg;
}

.slider-wrapper .swiper-wrapper {
  transition-timing-function: linear !important;
}
</style>
