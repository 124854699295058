<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    title: string
    isDurationVisible: boolean
    category?: string
    tag?: string
    count?: number
    itemsPerPage?: number
    showItemsOnDesktop?: number
    isMobileSlider?: boolean
    isDesktopAccordeon?: boolean
  }>(),
  {
    title: '',
    isDurationVisible: false,
    category: '',
    tag: '',
    count: 5,
    itemsPerPage: 8,
    showItemsOnDesktop: 4,
    isMobileSlider: false,
    isDesktopAccordeon: false,
  }
)
const filters = { category: props.category, tag: props.tag }
const { algoliaResults, pending: loading } = await useBoxEvents(
  filters,
  props.itemsPerPage
)
</script>
<template>
  <div>
    <ProductShowcase
      data-test-id="giftcard-events-section"
      :products="algoliaResults?.results?.[0]?.hits"
      image-loading="eager"
      :title-h-class="`legacy-h2`"
      :title="title"
      :is-duration-visible="false"
      :is-mobile-slider="isMobileSlider"
      :is-desktop-accordeon="isDesktopAccordeon"
      :show-items-on-desktop="showItemsOnDesktop"
      algolia-insights-label="Gift Ideas For You - Homepage"
      class="mt-8 md:mt-16"
    />
  </div>
</template>
